import { toast } from "react-hot-toast";

export const validation_partenaire = (form) => {
  if (!form.OrganismName) {
    toast.error("Nom de l'organisme est obligatoire");
    return false;
  }

  if (!form.Secteur) {
    toast.error("Secteur est obligatoire");
    return false;
  }

  if (!form.Pays) {
    toast.error("Pays est obligatoire");
    return false;
  }

  if (!form.Ville) {
    toast.error("Ville est obligatoire");
    return false;
  }

  if (!form.Tel) {
    toast.error("Téléphone est obligatoire");
    return false;
  }

  if (!form.Email) {
    toast.error("Email est obligatoire");
    return false;
  }

  if (!form.RepresenterName) {
    toast.error("Nom du représentant est obligatoire");
    return false;
  }

  if (!form.RepresenterTel) {
    toast.error("Téléphone du représentant est obligatoire");
    return false;
  }

  if (!form.RepresenterEmail) {
    toast.error("Email du représentant est obligatoire");
    return false;
  }

  if (!form.Message) {
    toast.error("Message est obligatoire");
    return false;
  }

  // Validate email format (simple check)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (form.Email && !emailRegex.test(form.Email)) {
    toast.error("Email invalide");
    return false;
  }

  if (form.RepresenterEmail && !emailRegex.test(form.RepresenterEmail)) {
    toast.error("Email du représentant invalide");
    return false;
  }

  return true; // All checks passed, return true
};
