import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import MainNavbar from "../../layouts/Navbars/main_navbar/MainNavbar";
import SecondNavbar from "../../layouts/Navbars/second_navbar/SecondNavbar";
import Footer from "../../layouts/Footer/Footer";
import ContactSection from "../../layouts/ContactSecion/ContactSection";

import PageHeader from "../../components/PageHeader/PageHeader";
import { Link, useLocation } from "react-router-dom";

import arrow from "../../assets/svgs/icons/media_arrow2.svg";
import useIsLink from "../../hooks/useIsLink";
import useAllTrue from "../../hooks/useAllTrue";
import MeetTheFaculty from "../../pages/HomePage/sections/MeetTheFaculty/MeetTheFaculty";
import useResolution from "../../hooks/useResolution";

function PageContainer(props) {
  const { title, actual_link, links, children, with_links = true } = props;
  const [current, setcurrent] = useState("");
  const [opened, setopened] = useState(false);

  const [actualLink, setActualLink] = useState(null);

  const [load, setLoad] = useState(true);

  const url = useLocation();
  const is_compus_fac = false;
  //useIsLink("/campus/faculty");
  const { sm } = useResolution();

  const is_comp_pc = useAllTrue([is_compus_fac, !sm]) || actualLink?.WithHeader;

  const handle_opener = () => {
    setopened(!opened);
  };

  useEffect(() => {
    setcurrent(`/${url.pathname.split("/")[2]}`);
  }, [url]);

  useEffect(() => {
    console.log(url.pathname.split("/")[2]);

    let actualLinkValue = links.find(
      (link) => link.link === `/${url.pathname.split("/")[2]}`
    );
    setActualLink(actualLinkValue);
  }, [links, url]);

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1);
  }, [url]);

  return (
    <div className={`${styles.main} ${!with_links && styles.full}`}>
      <MainNavbar />
      <SecondNavbar isCoched2={!is_comp_pc} />
      {actualLink && actualLink?.WithHeader && actualLink.HeaderSection}
      {load ? (
        <div className={styles.nothing} />
      ) : (
        <ActualPage
          actual_link={actual_link}
          title={title}
          opened={opened}
          actualLink={actualLink}
          with_links={with_links}
          links={links}
          current={current}
          handle_opener={handle_opener}
        >
          {children}
        </ActualPage>
      )}
      <ContactSection />
      <Footer />
    </div>
  );
}

const ActualPage = ({
  is_comp_pc,
  title,
  opened,
  with_links,
  actualLink,
  links,
  actual_link,
  current,
  handle_opener,
  children,
}) => {
  let WithHeader = actualLink?.WithHeader;

  return (
    <div
      className={`
        ${is_comp_pc ? styles.fac_container : styles.container}
        ${WithHeader ? styles.with_header : ""}`}
    >
      {!is_comp_pc && <PageHeader>{title}</PageHeader>}

      {is_comp_pc && <MeetTheFaculty in_home={false} />}

      <div className={styles.container2}>
        {with_links && (
          <div className={`${styles.links} ${opened && styles.opened}`}>
            {links.map((link, key) => {
              return (
                <Link key={key} to={`${actual_link}${link.link}`}>
                  <div
                    className={` 
                ${styles.link} 
                ${current === link.link && styles.active}`}
                  >
                    {link.name}
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        {with_links && (
          <div
            className={`${styles.opener} ${opened && styles.opener_opened}`}
            onClick={handle_opener}
          >
            <img src={arrow} alt="" />
          </div>
        )}

        <div className={styles.body}>
          <div className={styles.page}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
