import toast from "react-hot-toast";

export const init_data = {
  Nom: "",
  Prenom: "",
  TELEPHONE: "",
  EMAIL: "",
  obj: "",
  msg: "",
};

export const validation = (form) => {
  if (!form.Nom) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.Prenom) {
    toast.error("Prenom est obligatoire");
    return false;
  }

  if (!form.TELEPHONE) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.EMAIL) {
    toast.error("Email est obligatoire");
    return false;
  }

  if (!form.obj) {
    toast.error("Objet est obligatoire");
    return false;
  }

  if (!form.msg) {
    toast.error("Message est obligatoire");
    return false;
  }

  return true;
};
