import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../../../components/buttons/Buttons";

import exemple from "../../../../assets/images/aluminies/al1.png";
import { Swippers } from "../../../../components/Carossel/Carossel";
import { HR1 } from "../../../../components/HRs/HRs";
import { useNavigate } from "react-router-dom";
import customAxios from "../../../../utils/axios";

function Alumini() {
  const navigate = useNavigate();
  const toInscription = () => {
    navigate("/alum-join");
  };

  return (
    <div className={styles.main}>
      <LeftColoredTitle>UIK Alumni Network</LeftColoredTitle>

      <div className={styles.container2}>
        <p className={`${styles.desc}`}>
          L'Université Ibn Khaldoun compte aujourd'hui plus de 7000 diplômés
          issus de plus de trente nationalités différentes, qui ont poursuivi
          leur carrière dans divers domaines à travers le monde. L'association
          Alumni de l'UIK joue un rôle crucial en offrant un réseau
          professionnel visant à faciliter l'insertion et la mobilité
          professionnelle des anciens diplômés de l'université. UIK Alumni
          Network est une ressource essentielle pour les anciens étudiants de
          l'Université Ibn Khaldoun. Elle favorise le réseautage, le mentorat et
          l'accès à des opportunités professionnelles. Les anciens étudiants ont
          la possibilité de rester en contact avec leur alma mater grâce à cette
          association. De plus, elle propose des programmes de formation
          continue et des ateliers visant le développement professionnel. Par
          ailleurs, UIK Alumni Network offre un soutien précieux dans la
          recherche d'emploi et l'évolution de carrière grâce à son vaste réseau
          d'entreprises partenaires. Elle permet aux anciens étudiants de
          bénéficier du réseau et de l'expertise de professionnels chevronnés.
        </p>

        <InscrireBtn className={styles.btn} onClick={toInscription}>
          Rejoignez l’Association UIK <br /> Alumni
        </InscrireBtn>

        <HR1 />

        <AluminiesPart />

        <br />
        <br />
      </div>
    </div>
  );
}

const HexagoneImage = ({ img }) => {
  return (
    <div className={styles.img_container}>
      <img alt={"alumni"} src={process.env.REACT_APP_API_BACK + img} />
    </div>
  );
};

const AluminiesPart = () => {
  const [aluminies, setAluminies] = useState([]);
  const [current, setCurrent] = useState(-1);

  useEffect(() => {
    customAxios.get("/Alumni/getAll").then((res) => {
      setAluminies(res?.data || []);
      if (res?.data?.length > 0) setCurrent(0);
    });
  }, []);

  return (
    <>
      <LeftColoredTitle>Témoignages de nos alumni</LeftColoredTitle>

      <div className={styles.carossel}>
        {aluminies && aluminies.length > 0 && (
          <Aluminie
            img={aluminies[current]?.img}
            name={aluminies[current]?.fullname}
            title={aluminies[current]?.educ}
            description={aluminies[current]?.description}
          />
        )}

        <HR1 />

        {aluminies && (
          <Swippers
            current={current}
            size={aluminies.length}
            setCurrent={setCurrent}
          />
        )}
      </div>
    </>
  );
};

const Aluminie = ({
  img = exemple,
  name = "",
  title = "",
  description = "",
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.general}>
        <HexagoneImage img={img} />
        <h2>{name}</h2>
        <h2>{title}</h2>
      </div>
      <div className={styles.description}>
        <p
          className={`${styles.desc}`}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </div>
  );
};

export default Alumini;
