import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";

import partner from "../../assets/images/Partner.png";
import { InscrireBtn } from "../../components/buttons/Buttons";

import { data } from "./data";
import { Link } from "react-router-dom";

function Entreprise() {
  return (
    <div>
      <PageContainer title="ENTREPRISE" links={[]} with_links={false}>
        <div className={styles.container}>
          <LeftColoredTitle>Nos entreprises partenaires</LeftColoredTitle>

          <p className={styles.description}>
            L'Université Ibn Khaldoun possède un vaste réseau d'entreprises
            partenaires, dont le principal objectif est de favoriser la
            professionalisation des étudiants. Consciente de l'importance de
            l'expérience pratique dans la formation des futurs professionnels,
            l'université a intégré dès le début du cursus des classes de projets
            intégrés en collaboration avec INJAZ, une association à but non
            lucratif faisant partie du réseau international Junior Achievement
            Worldwide, présent dans 127 pays. Ces projets offrent aux étudiants
            l'occasion de travailler sur des problématiques réelles soumises par
            des entreprises, les préparant ainsi aux défis du monde
            professionnel.
            <br />
            <br />
            En plus de cela, l'université organise régulièrement des journées de
            l'entreprise, où les étudiants ont l'opportunité de rencontrer des
            représentants d'entreprises de différents secteurs et d'établir des
            contacts précieux pour leur future carrière. L'université consulte
            également régulièrement les référentiels de compétences et les
            acteurs économiques afin de s'assurer que ses programmes sont
            constamment mis à jour pour répondre aux besoins et aux compétences
            recherchées sur le marché du travail.
            <br />
            <br />
            Dans le cadre de son cursus, des visites d'entreprises, des stages
            de projet de fin d'études en licence, et des stages de mémoire en
            master font partie intégrante de la formation. Ces opportunités
            permettent aux étudiants de mettre en pratique leurs connaissances
            théoriques, de développer des compétences spécifiques et de se
            familiariser avec le fonctionnement réel des entreprises.
            <br />
            <br />
            Grâce à ce large réseau d'entreprises et à ces initiatives axées sur
            la professionnalisation, l'Université Ibn Khaldoun s'engage à
            préparer ses étudiants à une transition harmonieuse vers le monde
            professionnel et à les doter des compétences et des connaissances
            nécessaires pour réussir dans leur carrière.
          </p>

          <div className={styles.partners}>
            {data.map((parten) => {
              return <img src={parten} className={styles.partner} alt="" />;
            })}
            {/* <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" />
            <img     src={partner} className={styles.partner} alt="" /> */}
          </div>

          <Link to="/partenaire-join" className={styles.decouvrir}>
            <InscrireBtn> Devenez notre partenaire </InscrireBtn>
          </Link>
        </div>
      </PageContainer>
    </div>
  );
}

export default Entreprise;
