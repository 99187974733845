import { toast } from "react-hot-toast";

export const validation_alumni = (form) => {
  if (!form.Nom) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.Prenom) {
    toast.error("Prenom est obligatoire");
    return false;
  }

  if (!form.Tel) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.Email) {
    toast.error("Email est obligatoire");
    return false;
  }

  if (!form.Diplome) {
    toast.error("Diplome est obligatoire");
    return false;
  }

  if (!form.Promotion) {
    toast.error("Promotion est obligatoire");
    return false;
  }

  if (!form.VousEtes) {
    toast.error("Vous Etes est obligatoire");
    return false;
  }

  if (!form.PositionActuelle) {
    toast.error("Position Actuelle est obligatoire");
    return false;
  }

  if (!form.Entreprise) {
    toast.error("Entreprise est obligatoire");
    return false;
  }

  if (!form.Secteur) {
    toast.error("Secteur est obligatoire");
    return false;
  }

  if (!form.Pays) {
    toast.error("Pays est obligatoire");
    return false;
  }

  if (!form.DelaiPremierTravail) {
    toast.error("Delai Premier Travail est obligatoire");
    return false;
  }

  if (!form.Description) {
    toast.error("Description est obligatoire");
    return false;
  }

  // Validate email format (simple check)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (form.Email && !emailRegex.test(form.Email)) {
    toast.error("Email invalide");
    return false;
  }

  // Optional: validate that photos is an array and has at least one item (if required)
  if (!form?.photos || form?.photos?.length === 0) {
    toast.error("Au moins une photo est requise");
    return false;
  }

  return true; // All checks passed, return true
};
