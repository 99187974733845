import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import PageContainer from "../../Common/PageContainer/PageContainer";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Presentation from "./SubPages/Presentation/Presentation";
import Alumini from "./SubPages/Alumini/Alumini";
import FAQ from "./SubPages/FAQ/FAQ";
import Actuality from "./SubPages/Actuality/Actuality";
import OneEvent from "./SubPages/OneEvent/OneEvent";
import Mediatheque from "./SubPages/Mediatheque/Mediatheque";
import EquipePedago from "./SubPages/EquipePedago/EquipePedago";
import StudentLife from "./SubPages/StudentLife/StudentLife";
import StudentLifeHeader from "./SubPages/StudentLife/StudentLifeHeader";

function CAMPUS(props) {
  const links = [
    {
      name: "Présentation",
      link: "/presentation",
      WithHeader: false,
      HeaderSection: <></>,
    },
    {
      name: "Vie estudiantine",
      link: "/vie_estudiantine",
      WithHeader: true,
      HeaderSection: <StudentLifeHeader />,
    },
    {
      name: "Alumni",
      link: "/alumni",
      WithHeader: false,
      HeaderSection: <></>,
    },
    {
      name: "Equipe pédagogique",
      link: "/faculty",
      WithHeader: false,
      HeaderSection: <></>,
    },
    {
      name: "Actualités",
      link: "/actualites",
      WithHeader: false,
      HeaderSection: <></>,
    },
    {
      name: "Médiathèque",
      link: "/mediatheque",
      WithHeader: false,
      HeaderSection: <></>,
    },
    { name: "FAQ", link: "/faq", WithHeader: false, HeaderSection: <></> },
  ];

  // useEffect(() => {
  //   // navig(`/campus/presentation`);
  // }, []);

  return (
    <div className={styles.main}>
      <PageContainer title="Campus" actual_link="/campus" links={links}>
        <Routes>
          <Route path="/actualites/:event" element={<OneEvent />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/alumni" element={<Alumini />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/actualites" element={<Actuality />} />
          <Route path="/mediatheque" element={<Mediatheque />} />
          <Route path="/vie_estudiantine" element={<StudentLife />} />
          <Route path="/faculty" element={<EquipePedago />} />
          <Route path="/*" element={<Navigate to={"/campus/presentation"} />} />
        </Routes>
      </PageContainer>
    </div>
  );
}

export default CAMPUS;
