import React, { useState } from "react";
import styles from "./styles.module.scss";
import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../components/buttons/Buttons";
import { toast } from "react-hot-toast";
import { validation_partenaire } from "./partenaire_validation";
import { inscriptionEntreprise } from "../../services/inscription";

function PartnerForm() {
  const [form, setForm] = useState({
    OrganismName: "",
    Secteur: "",
    Pays: "",
    Ville: "",
    Tel: "",
    Email: "",
    RepresenterName: "",
    RepresenterTel: "",
    RepresenterEmail: "",
    Message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setForm({
      OrganismName: "",
      Secteur: "",
      Pays: "",
      Ville: "",
      Tel: "",
      Email: "",
      RepresenterName: "",
      RepresenterTel: "",
      RepresenterEmail: "",
      Message: "",
    });
  };

  const handleSubmit = () => {
    if (validation_partenaire(form) === false) return;
    setLoading(true);

    inscriptionEntreprise(form)
      .then((res) => {
        toast.success("Formulaire envoyé avec succès");
        setLoading(false);
        handleReset();
      })
      .catch((err) => {
        toast.error("Erreur lors de l'envoi du formulaire");
        setLoading(false);
      });
  };

  return (
    <PageContainer
      title="Devenez notre partenaire!"
      links={[]}
      with_links={false}
    >
      <div className={styles.container}>
        <LeftColoredTitle>Devenez notre partenaire</LeftColoredTitle>

        <div className={styles.form}>
          <div className={styles.w50}>
            <label>Nom de l’organisme</label>
            <input
              name="OrganismName"
              value={form.OrganismName || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>Secteur</label>
            <select
              name="Secteur"
              value={form.Secteur || ""}
              onChange={handleChange}
            >
              <option value="">- Sélectionner -</option>
              <option value="Informatique et Télécoms">
                Informatique et Télécoms
              </option>
              <option value="Finance">Finance</option>
              <option value="Santé">Santé</option>
              <option value="Éducation">Éducation</option>
              <option value="Construction">Construction</option>
              <option value="Transport et Logistique">
                Transport et Logistique
              </option>
              <option value="Tourisme et Hôtellerie">
                Tourisme et Hôtellerie
              </option>
              <option value="Énergie">Énergie</option>
              <option value="Commerce de détail">Commerce de détail</option>
              <option value="Industrie manufacturière">
                Industrie manufacturière
              </option>
              <option value="Médias et Communication">
                Médias et Communication
              </option>
              <option value="Agriculture et Agroalimentaire">
                Agriculture et Agroalimentaire
              </option>
              <option value="Immobilier">Immobilier</option>
              <option value="Services professionnels">
                Services professionnels
              </option>
              <option value="Arts et Culture">Arts et Culture</option>
              <option value="Sport et Loisirs">Sport et Loisirs</option>
              <option value="Recherche et Développement">
                Recherche et Développement
              </option>
              <option value="Environnement et Développement durable">
                Environnement et Développement durable
              </option>
              <option value="Sécurité et Défense">Sécurité et Défense</option>
              <option value="Pharmaceutique">Pharmaceutique</option>
              <option value="Aéronautique et Spatial">
                Aéronautique et Spatial
              </option>
              <option value="Automobile">Automobile</option>
              <option value="Services publics">Services publics</option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div className={styles.w50}>
            <label>Pays</label>
            <input
              name="Pays"
              value={form.Pays || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>Ville</label>
            <input
              name="Ville"
              value={form.Ville || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>Téléphone</label>
            <input
              name="Tel"
              value={form.Tel || ""}
              onChange={handleChange}
              placeholder="e.g., +216 12345678"
            />
          </div>

          <div className={styles.w50}>
            <label>Email</label>
            <input
              name="Email"
              value={form.Email || ""}
              onChange={handleChange}
              type="email"
            />
          </div>

          <div className={styles.w50}>
            <label>Nom du représentant</label>
            <input
              name="RepresenterName"
              value={form.RepresenterName || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>Téléphone du représentant</label>
            <input
              name="RepresenterTel"
              value={form.RepresenterTel || ""}
              onChange={handleChange}
              placeholder="e.g., +216 12345678"
            />
          </div>

          <div className={styles.w50}>
            <label>Email du représentant</label>
            <input
              name="RepresenterEmail"
              value={form.RepresenterEmail || ""}
              onChange={handleChange}
              type="email"
            />
          </div>

          <div className={styles.w100}>
            <label>Message</label>
            <textarea
              name="Message"
              value={form.Message || ""}
              onChange={handleChange}
              rows={5}
            />
          </div>

          <div className={styles.actions}>
            <InscrireBtn
              onClick={handleSubmit}
              disabled={loading}
              className={styles.submitBtn}
            >
              {loading ? "Envoi en cours..." : "Envoyer"}
            </InscrireBtn>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default PartnerForm;
