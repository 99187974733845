import axios from "../utils/axios";

export const getDeplomesNames = () => {
  return axios.get("/Diploma/getAllButOnlyNames");
};

export const inscription = (data) => {
  return axios.post("/Person/formInsc", { ...data });
};

export const inscriptionAlumnie = (data) => {
  return axios.post("/Alumni-insc/add", { ...data });
};

export const inscriptionRecruteDemand = (data) => {
  return axios.post("/recrute-demand/add", { ...data });
};

export const inscriptionEntreprise = (data) => {
  return axios.post("/Entreprise-insc/add", { ...data });
};

export const PreInscription = (data) => {
  return axios.post("/Person/formPotential", { ...data });
};

export const ContactUsServ = (data) => {
  return axios.post("/ContactUs/add", { ...data });
};
