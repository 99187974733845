import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";

import vie_img from "../../../../assets/images/campus/vie_est.png";
import img1 from "../../../../assets/images/campus/1.jpg";
import img2 from "../../../../assets/images/campus/2.jpg";
import img3 from "../../../../assets/images/campus/3.jpg";
import img4 from "../../../../assets/images/campus/4.jpg";
import img5 from "../../../../assets/images/campus/5.jpg";
import img6 from "../../../../assets/images/campus/6.jpg";
import img7 from "../../../../assets/images/campus/7.jpg";
import img8 from "../../../../assets/images/campus/8.jpg";
import img9 from "../../../../assets/images/campus/9.jpg";
import img10 from "../../../../assets/images/campus/10.jpg";
import img11 from "../../../../assets/images/campus/11.jpg";
import img12 from "../../../../assets/images/campus/12.jpg";
import img13 from "../../../../assets/images/campus/13.jpg";
import ScrollNow from "../../../../components/ScrollNow/ScrollNow";
import useResolution from "../../../../hooks/useResolution";
import logo from "../../../../assets/svgs/logos/logo_blue.svg";

const images = [
  vie_img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
];

function StudentLifeHeader() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const { sm } = useResolution();

  const ScrollScreen = () => {
    let offset = sm ? 60 : 0;
    window.scrollTo({
      top: (ref?.current.offsetHeight || window.innerHeight) - offset,
      behavior: "smooth",
    });
  };

  return (
    <div ref={ref} className={`${styles.carossel}`}>
      <div className={`w-full h-full relative overflow-hidden`}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}

        <div
          className={`absolute w-full h-full bg-[#00000099] flex items-center justify-center ${styles["scroll-indicator"]}`}
        >
          {/* <img src={logo} alt="" className={styles.logo} /> */}
          <h1>Vie estudiantine</h1>
          <ScrollNow onClick={ScrollScreen} />
        </div>
      </div>
    </div>
  );
}

export default StudentLifeHeader;
