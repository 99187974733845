import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../components/buttons/Buttons";
import { toast } from "react-hot-toast";
import FileUpload from "../../components/FileUpload/FileUpload";
import { validation_alumni } from "./alum_validation";
import {
  inscriptionAlumnie,
  getDeplomesNames,
} from "../../services/inscription";

function AlumniForm() {
  const [form, setForm] = useState({
    Nom: "",
    Prenom: "",
    Tel: "",
    Email: "",
    Diplome: "",
    Promotion: "",
    VousEtes: "",
    PositionActuelle: "",
    Entreprise: "",
    Secteur: "",
    Pays: "",
    DelaiPremierTravail: "",
    Description: "",
    photos: [],
  });
  const [loading, setLoading] = useState(false);

  const [deplomes, setDeplomes] = useState({
    ARCHITECTURE: [],
    BUSINESS: [],
    DESIGN: [],
    DROIT: [],
    INFORMATIQUE: [],
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setForm({
      Nom: "",
      Prenom: "",
      Tel: "",
      Email: "",
      Diplome: "",
      Promotion: "",
      VousEtes: "",
      PositionActuelle: "",
      Entreprise: "",
      Secteur: "",
      Pays: "",
      DelaiPremierTravail: "",
      Description: "",
      photos: [],
    });
  };

  const handleSubmit = () => {
    if (validation_alumni(form) === false) return;
    setLoading(true);

    inscriptionAlumnie(form)
      .then((res) => {
        toast.success("Formulaire envoyé avec succès");
        setLoading(false);
        handleReset();
      })
      .catch((err) => {
        toast.error("Erreur lors de l'envoi du formulaire");
        setLoading(false);
      });
  };

  useEffect(() => {
    getDeplomesNames().then((resp) => {
      setDeplomes(resp.data);
    });
  }, []);

  return (
    <PageContainer title="Formulaire Alumni" links={[]} with_links={false}>
      <div className={styles.container}>
        <LeftColoredTitle>Formulaire Alumni</LeftColoredTitle>

        <div className={styles.form}>
          <div className={styles.w50}>
            <label>Nom</label>
            <input name="Nom" value={form.Nom || ""} onChange={handleChange} />
          </div>

          <div className={styles.w50}>
            <label>Prénom</label>
            <input
              name="Prenom"
              value={form.Prenom || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>Num de Tel (avec préfixe)</label>
            <input
              name="Tel"
              value={form.Tel || ""}
              onChange={handleChange}
              placeholder="e.g., +216 12345678"
            />
          </div>

          <div className={styles.w50}>
            <label>Email</label>
            <input
              name="Email"
              value={form.Email || ""}
              onChange={handleChange}
              type="email"
            />
          </div>

          <div className={styles.w100}>
            <label>Dernier diplôme obtenu à l’UIK</label>
            <select
              name="Diplome"
              value={form.Diplome || ""}
              onChange={handleChange}
            >
              <option style={{ fontWeight: "bold" }} selected={true}>
                - Business -
              </option>

              <option value="Licence en Marketing">Licence en Marketing</option>
              <option value="Licence en Management">
                Licence en Management
              </option>
              <option value="Licence en Finance">Licence en Finance</option>
              <option value="Licence en Comptabilité">
                Licence en Comptabilité
              </option>
              <option value="Licence en Business Intelligence">
                Licence en Business Intelligence
              </option>
              <option value="Master Marketing Digital">
                Master Marketing Digital
              </option>
              <option value="Master Ingénierie Financière">
                Master Ingénierie Financière
              </option>
              <option value="Master Fintech">Master Fintech</option>
              <option value="Master CCA">Master CCA</option>
              <option value="Autre-Business">Autre</option>

              <option
                style={{ fontWeight: "bold" }}
                selected={false}
                disabled={true}
              >
                - Droit -
              </option>

              <option value="Licence en Droit Privé">
                Licence en Droit Privé
              </option>
              <option value="Licence en Droit Public">
                Licence en Droit Public
              </option>
              <option value="Master Droit des Affaires">
                Master Droit des Affaires
              </option>
              <option value="Master Droit Judiciaire">
                Master Droit Judiciaire
              </option>
              <option value="Master Droit International">
                Master Droit International
              </option>
              <option value="Master Sciences Politiques">
                Master Sciences Politiques
              </option>
              <option value="Autre-Droit">Autre</option>

              <option
                style={{ fontWeight: "bold" }}
                selected={false}
                disabled={true}
              >
                - Design -
              </option>

              <option value="">- Sélectionner -</option>
              <option value="Licence en Design Graphique">
                Licence en Design Graphique
              </option>
              <option value="Licence en Architecture d’Intérieur">
                Licence en Architecture d’Intérieur
              </option>
              <option value="Master en Architecture d’Intérieur">
                Master en Architecture d’Intérieur
              </option>
              <option value="Autre-Design">Autre</option>

              <option
                style={{ fontWeight: "bold" }}
                selected={false}
                disabled={true}
              >
                - Informatique -
              </option>

              <option value="Cycle Ingénieur : Systèmes d’Information">
                Cycle Ingénieur : Systèmes d’Information
              </option>
              <option value="Cycle Ingénieur : Ingénierie Logicielle">
                Cycle Ingénieur : Ingénierie Logicielle
              </option>
              <option value="Cycle Ingénieur : Telecom-Réseau">
                Cycle Ingénieur : Telecom-Réseau
              </option>
              <option value="Cycle Ingénieur : Game Dev">
                Cycle Ingénieur : Game Dev
              </option>

              <option
                style={{ fontWeight: "bold" }}
                selected={false}
                disabled={true}
              >
                - Architecture -
              </option>
              <option value="Diplôme National d’Architecte">
                Diplôme National d’Architecte
              </option>
              <option value="Master BIM">Master BIM</option>
              <option value="Master Smart Architecture">
                Master Smart Architecture
              </option>
              <option value="Master Smart City">Master Smart City</option>
            </select>
          </div>

          <div className={styles.w50}>
            <label>Promotion</label>
            <select
              name="Promotion"
              value={form.Promotion || ""}
              onChange={handleChange}
            >
              {Array.from(
                { length: new Date().getFullYear() - 2005 + 1 },
                (_, i) => 2005 + i
              )
                .reverse()
                .map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
            </select>
          </div>

          <div className={styles.w50}>
            <label>Vous êtes</label>
            <select
              name="VousEtes"
              value={form.VousEtes || ""}
              onChange={handleChange}
            >
              <option value="">- Sélectionner -</option>
              <option value="Salarié(e) privé">Salarié(e) privé</option>
              <option value="Salarié(e) public">Salarié(e) public</option>
              <option value="Libéral(e)">Libéral(e)</option>
              <option value="Entrepreneur">Entrepreneur</option>
              <option value="Free-lanceur">Free-lanceur</option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div className={styles.w50}>
            <label>Position actuelle</label>
            <input
              name="PositionActuelle"
              value={form.PositionActuelle || ""}
              onChange={handleChange}
              placeholder="e.g., Manager, Developer"
            />
          </div>

          <div className={styles.w50}>
            <label>Entreprise</label>
            <input
              name="Entreprise"
              value={form.Entreprise || ""}
              onChange={handleChange}
              placeholder="e.g., Google, Microsoft"
            />
          </div>

          <div className={styles.w100}>
            <label>Secteur</label>
            <select
              name="Secteur"
              value={form.Secteur || ""}
              onChange={handleChange}
            >
              <option value="">- Sélectionner -</option>
              <option value="Informatique et Télécoms">
                Informatique et Télécoms
              </option>
              <option value="Finance">Finance</option>
              <option value="Santé">Santé</option>
              <option value="Éducation">Éducation</option>
              <option value="Construction">Construction</option>
              <option value="Transport et Logistique">
                Transport et Logistique
              </option>
              <option value="Tourisme et Hôtellerie">
                Tourisme et Hôtellerie
              </option>
              <option value="Énergie">Énergie</option>
              <option value="Commerce de détail">Commerce de détail</option>
              <option value="Industrie manufacturière">
                Industrie manufacturière
              </option>
              <option value="Médias et Communication">
                Médias et Communication
              </option>
              <option value="Agriculture et Agroalimentaire">
                Agriculture et Agroalimentaire
              </option>
              <option value="Immobilier">Immobilier</option>
              <option value="Services professionnels">
                Services professionnels
              </option>
              <option value="Arts et Culture">Arts et Culture</option>
              <option value="Sport et Loisirs">Sport et Loisirs</option>
              <option value="Recherche et Développement">
                Recherche et Développement
              </option>
              <option value="Environnement et Développement durable">
                Environnement et Développement durable
              </option>
              <option value="Sécurité et Défense">Sécurité et Défense</option>
              <option value="Pharmaceutique">Pharmaceutique</option>
              <option value="Aéronautique et Spatial">
                Aéronautique et Spatial
              </option>
              <option value="Automobile">Automobile</option>
              <option value="Services publics">Services publics</option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div className={styles.w50}>
            <label>Pays</label>
            <select name="Pays" value={form.Pays || ""} onChange={handleChange}>
              <option value="">- Sélectionner -</option>
              <option value="Tunisie">Tunisie</option>
              <option value="France">France</option>
              <option value="Canada">Canada</option>
              <option value="États-Unis">États-Unis</option>
              <option value="Royaume-Uni">Royaume-Uni</option>
              <option value="Allemagne">Allemagne</option>
              <option value="Espagne">Espagne</option>
              <option value="Italie">Italie</option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div className={styles.w50}>
            <label>Délai d’obtention du premier travail après le diplôme</label>
            <select
              name="DelaiPremierTravail"
              value={form.DelaiPremierTravail || ""}
              onChange={handleChange}
            >
              <option value="">- Sélectionner -</option>
              <option value="Avant 3 mois">Avant 3 mois</option>
              <option value="Entre 3 mois et 6 mois">
                Entre 3 mois et 6 mois
              </option>
              <option value="Entre 6 mois et 9 mois">
                Entre 6 mois et 9 mois
              </option>
              <option value="Entre 9 mois et 12 mois">
                Entre 9 mois et 12 mois
              </option>
              <option value="Plus d’un an">Plus d’un an</option>
            </select>
          </div>

          <FileUpload
            label="Ajouter des photos"
            className={styles.w100}
            name="photos"
            value={form.photos}
            onChange={handleChange}
          />

          <div className={styles.w100}>
            <label>Description ou autre</label>

            <textarea
              value={form.Description}
              onChange={handleChange}
              name="Description"
              rows={5}
            />
          </div>

          <div className={styles.actions}>
            <InscrireBtn
              onClick={handleSubmit}
              disabled={loading}
              className={styles.submitBtn}
            >
              {loading ? "Envoi en cours..." : "Envoyer"}
            </InscrireBtn>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default AlumniForm;
