import React from "react";
import styles from "./styles.module.scss";
import LeftColoredTitle from "../../../../components/Titles/LeftColoredTitle";

import fac1 from "../../../../assets/svgs/logos/facs/1.svg";
import fac2 from "../../../../assets/svgs/logos/facs/2.svg";
import fac3 from "../../../../assets/svgs/logos/facs/3.svg";
import fac4 from "../../../../assets/svgs/logos/facs/4.svg";
import fac5 from "../../../../assets/svgs/logos/facs/5.svg";

import vision from "../../../../assets/svgs/icons/presentation_icons/vision.svg";
import mission from "../../../../assets/svgs/icons/presentation_icons/mission.svg";
import values from "../../../../assets/svgs/icons/presentation_icons/values.svg";

import fac_img from "../../../../assets/images/campus/fac.jpg";
import { HR1 } from "../../../../components/HRs/HRs";

function Presentation() {
  return (
    <div className={styles.main}>
      <LeftColoredTitle>UNIVERSITÉ IBN KHALDOUN</LeftColoredTitle>
      <div className={styles.container2}>
        <p className={styles.desc}>
          Fondée en 2005, l’UIK est une université Tunisienne regroupant 5
          écoles :
        </p>
      </div>
      <div className={styles.faculties}>
        <img src={fac1} alt="uik business" />
        <img src={fac2} alt="uik design" />
        <img src={fac3} alt="uik architecture" />
      </div>
      <div className={styles.faculties}>
        <img src={fac4} alt="uik droit" />
        <img src={fac5} alt="uik informatique" />
      </div>
      <br />
      <HR1 />
      <div className={styles.container2}>
        <DescriptItem img={vision} title="VISION">
          L’UIK ambitionne d’être un hub pédagogique et scientifique dans
          l’Afrique du Nord et le monde arabe, reconnue pour son excellence
          académique, sa pédagogie innovante et sa contribution à la formation
          de leaders authentiques et créatifs, à l’impact positif et constructif
          sur le monde des affaires et de la société.
        </DescriptItem>

        <DescriptItem img={mission} title="MISSION">
          L’UIK, une université à visage humain, a pour mission de  créer un
          environnement  stimulant et inspirant aux méthodes d’apprentissage
          innovantes, où la pratique et la collaboration sont privilégiées, pour
          former des diplômés capables de relever les défis du monde réel.
        </DescriptItem>

        <DescriptItem img={values} title="VALEURS">
          <Valeurs />
        </DescriptItem>
      </div>
      <HR1 />

      <div className={styles.container2}>
        <p className={styles.desc}>L’UIK est implantée sur deux sites :</p>

        <h2 className={styles.sub_title}>Le campus de la Soukra :</h2>

        <img src={fac_img} alt="UIK Campus" className={styles.fac_img} />

        <p className={`${styles.desc} ${styles.parag}`}>
          Le campus de la Soukra de l'UIK, niché dans un cadre idyllique,
          s'étend sur une vaste superficie de 15 000 m2 répartie entre salle de
          cours, ateliers, amphithéâtre, laboratoires, locaux administratifs,
          parking, buvette et restaurant.
        </p>

        <p className={`${styles.desc} ${styles.parag}`}>
          Le campus de la Soukra est situé dans la banlieue nord de Tunis, à
          proximité immédiate de l'aéroport international de Tunis-Carthage, à
          quelques kilomètres de la cité antique de Carthage, des plages de la
          Marsa et de Gammarth, du port de plaisance de Sidi Bou Saïd.
        </p>

        <h2 className={styles.sub_title}>Le site de Montplaisir :</h2>

        <p className={`${styles.desc} ${styles.parag}`}>
          Le site de Montplaisir consiste en un bâtiment de 5 étages d’une
          superficie de 5000 m2 avec des salles équipées, une bibliothèque, une
          salle de loisir, une salle de conférence, ainsi que plusieurs salles
          d’informatique. Le site est idéalement situé à cinq minutes du centre
          historique de la capitale Tunis, ou se trouve notamment le théâtre
          municipal, le vieux Tunis, la mosquée Zitouna (bâtie il y a 13
          siècles), ou encore la cathédrale Saint-Vincent-de-Paul, le site se
          veut accessible par les différents modes de transport (métro, bus,
          taxi).
        </p>
      </div>
    </div>
  );
}

const Valeurs = () => {
  const values = [
    "Passion",
    "Authenticité",
    "Innovation",
    "Singularité",
    "Humanisme",
    "Curiosité",
    "Créativité",
  ];

  return (
    <div
      className={`flex items-start justify-start flex-wrap ${styles.values}`}
    >
      {values.map((value, index) => {
        return (
          <div
            key={index}
            className={`bg-[#E2F5FA] text-[#0aaedb] font-medium px-4 py-3 mr-3 mb-3 rounded-md ${styles.value}`}
          >
            {value}
          </div>
        );
      })}
    </div>
  );
};

const DescriptItem = ({ img, title, children }) => {
  return (
    <div className={styles.desc_item}>
      <div className={styles.hexagone}>
        <div className={styles.inside}>
          <img src={img} alt="" />
          <h2>{title}</h2>
        </div>
      </div>
      <p>{children}</p>
    </div>
  );
};

export default Presentation;
