import React, { useEffect } from "react";
import "./App.scss";
import Home from "./pages/HomePage/Home";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import University from "./pages/Universites/University/University";
import { useDispatch, useSelector } from "react-redux";
import { ChangeTheme } from "./redux/colors/colors.actions";
import Faculty from "./pages/Faculty/Faculty";
import Admission from "./pages/ADMISSION/Admission";
import Campus from "./pages/CAMPUS/Campus";
import International from "./pages/INTERNATIONAL/International";
import Entreprise from "./pages/ENTREPRISE/Entreprise";
import Inscription from "./pages/Inscription/Inscription";
import CustomToaster from "./components/Toast/Toast";
import ContactUs from "./pages/ContactUs/ContactUs";
import useScrollToTopOnRouteChange from "./hooks/useToTop";
import UnderConstruction from "./pages/UnderConstruction/Model1/Model";
import Director from "./pages/Director/Director";
import Teacher from "./pages/Director/Teacher";
// import Loading from "./pages/UnderConstruction/Model1/Model";
// import Loading from "./pages/Loading/Loading";
// import Formation from "./pages/FORMATION/Formation";
import Formation from "./pages/FORMATIONS/Formations";
import AllFormations from "./pages/FORMATIONS/AllFormations";
import SuccInsctiption from "./pages/Inscription/SuccInsctiption";
import AlumniForm from "./pages/Inscription/AlumniInscript";
import PartnerForm from "./pages/Inscription/PartenaireInscript";
import Recrute from "./pages/Inscription/Recrute";

function App() {
  const theme = useSelector((state) => state.ColorReducer.theme);
  const dispatch = useDispatch();
  const location = useLocation();
  useScrollToTopOnRouteChange();

  // here he test if http we open a https link
  useEffect(() => {
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.href = window.location.href.replace(
        "http://",
        "https://"
      );
    }
  }, []);

  useEffect(() => {
    if (location) {
      let link = location.pathname.split("/")[2];
      switch (link) {
        case "home":
          dispatch(ChangeTheme("theme-blue"));
          return;
        case "buiness":
          dispatch(ChangeTheme("theme-yellow"));
          return;
        case "droit":
          dispatch(ChangeTheme("theme-red"));
          return;
        case "design":
          dispatch(ChangeTheme("theme-pink"));
          return;
        case "architecture":
          dispatch(ChangeTheme("theme-purple"));
          return;
        case "informatique":
          dispatch(ChangeTheme("theme-blue"));
          return;

        default:
          dispatch(ChangeTheme("theme-blue"));
          return;
      }
    }
  }, [location]);

  return (
    <div className={`App ${theme}`}>
      <CustomToaster />
      {/* <Loading /> */}
      <Routes>
        <Route path="/home" element={<Home />} />

        <Route path="/faculty" element={<Faculty />} />
        <Route path="/director" element={<Director />} />
        <Route path="/faculty/:flen_foulani" element={<Teacher />} />
        <Route path="/admission/*" element={<Admission />} />
        <Route path="/campus/*" element={<Campus />} />
        <Route path="/international" element={<International />} />
        <Route path="/entreprise" element={<Entreprise />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="/alum-join" element={<AlumniForm />} />
        <Route path="/partenaire-join" element={<PartnerForm />} />
        <Route path="/recrute" element={<Recrute />} />
        <Route path="/inscription-success" element={<SuccInsctiption />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/formations" element={<AllFormations />} />
        <Route path="/formations/*" element={<Formation />} />
        {/* <Route path="/alternance/*" element={<ALTERNANCE />} /> */}
        {/* <Route path="/alternance/*" element={<UnderConstruction />} /> */}
        <Route path="/under-construction" element={<UnderConstruction />} />

        <Route path="/university" element={<University />} />
        <Route path="/university/:univ" element={<University />} />
        <Route path="/university/:univ/:type" element={<University />} />
        <Route path="/university/:univ/:type/:id" element={<University />} />

        {/* <Route path="/buiness" element={<Buiseness />} />
        <Route path="/buiness/:type" element={<Buiseness />} />
        <Route path="/buiness/:type/:id" element={<Buiseness />} />
        <Route path="/droit" element={<Droit />} />
        <Route path="/droit/:type" element={<Droit />} />
        <Route path="/droit/:type/:id" element={<Droit />} />
        <Route path="/design" element={<Design />} />
        <Route path="/design/:type" element={<Design />} />
        <Route path="/design/:type/:id" element={<Design />} />
        <Route path="/informatique" element={<Informatique />} />
        <Route path="/informatique/:type" element={<Informatique />} />
        <Route path="/informatique/:type/:id" element={<Informatique />} />
        <Route path="/architecture" element={<Architecture />} />
        <Route path="/architecture/:type" element={<Architecture />} />
        <Route path="/architecture/:type/:id" element={<Architecture />} /> */}

        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  );
}

export default App;
