import { toast } from "react-hot-toast";

export const jobApplicationValidation = (form) => {
  if (!form.Nom) {
    toast.error("Nom est obligatoire");
    return false;
  }

  if (!form.Prenom) {
    toast.error("Prenom est obligatoire");
    return false;
  }

  if (!form.Tel) {
    toast.error("Telephone est obligatoire");
    return false;
  }

  if (!form.Email) {
    toast.error("Email est obligatoire");
    return false;
  }

  if (!form.CV_LINK) {
    toast.error("CV LINK est obligatoire");
    return false;
  }

  if (!form.jobType) {
    toast.error("Job Type est obligatoire");
    return false;
  }

  // Validate email format
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(form.Email)) {
    toast.error("Email invalide");
    return false;
  }

  if (form.jobType === "ENSEIGNANT") {
    if (!form.POSTE_SOUHAITEE) {
      toast.error("Poste Souhaitée est obligatoire");
      return false;
    }

    if (!form.YOU_ARE) {
      toast.error("You Are est obligatoire");
      return false;
    }

    if (!form.DERNIER_DIPLOME_OBTENU) {
      toast.error("Dernier Diplôme Obtenu est obligatoire");
      return false;
    }

    if (!form.SPECIALITE) {
      toast.error("Spécialité est obligatoire");
      return false;
    }

    if (!form.DOMAINES_EXPERTISE) {
      toast.error("Domaines d'Expertise est obligatoire");
      return false;
    }

    if (!form.COURS_ENSEIGNES) {
      toast.error("Cours Enseignés est obligatoire");
      return false;
    }

    if (!form.LANGUE_ENSEIGNEMENT) {
      toast.error("Langue d'Enseignement est obligatoire");
      return false;
    }
  } else {
    if (!form.SERVICE_CONCERNE) {
      toast.error("Service Concerné est obligatoire");
      return false;
    }
  }

  return true; // All checks passed
};
