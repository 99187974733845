import ImgBack from "../Image/ImgBack";
import { LoaderCircle, Plus, Trash, Trash2 } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import customAxios from "../../utils/axios";

const Obl = () => <span className="red">*</span>;

function FilePDFUpload({
  label = "",
  className = "",
  labelClassName = "",
  value = "",
  name = "CV_LINK",
  onChange = () => {},
  required = false,
}) {
  const add_file = (img) => {
    onChange({ target: { name, value: img } });
  };

  const remove_img = () => {
    onChange({ target: { name, value: "" } });
  };

  return (
    <div className={`w-full ${className}`}>
      <label className={`mb-[0.8vw] ${labelClassName}`}>
        {label}
        {required && <Obl />}
      </label>
      <div className=" flex items-center justify-start flex-wrap">
        {value && (
          <ExistFile src={value} alt="img" onClick={() => remove_img()} />
        )}
        {!value && <AddFile add_file={add_file} />}
      </div>
    </div>
  );
}

const ExistFile = ({ src = "", alt = "", onClick = () => {} }) => {
  let backend_url = process.env.REACT_APP_API_BACK;

  return (
    <div className="w-full md:w-full h-[12vw] md:h-[4vw] mr-[1vw] mb-[1vw] bg-[#F4F5F5] rounded-[1vw] border-[2px] border-gray-300 relative overflow-hidden">
      <div
        onClick={onClick}
        className="w-[1.5vw] h-[1.5vw] bg-[#F4F5F5] rounded-full absolute top-[0.3vw] right-[0.3vw] cursor-pointer flex items-center justify-center"
      >
        <Trash2 color="#4F4F4F" className="w-[0.9vw] h-[0.9vw]" />
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <h2
          className="font-medium"
          // className="font-medium hover:underline cursor-pointer"
          // onClick={() => {
          //   window.open(backend_url + src, "_blank");
          // }}
        >
          Téléchargé avec succès ( {src?.split("_____UIK_____")[1]} ){" "}
        </h2>
      </div>

      {/* <ImgBack src={src} alt={alt} className="w-full h-full object-cover" /> */}
    </div>
  );
};

const AddFile = ({ add_file = () => {} }) => {
  const [Image, setImage] = useState({
    url: "",
    uploading: false,
    done: false,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    console.log(Image);
    if (Image.done) {
      add_file(Image.url);
      setImage({ url: "", uploading: false, done: false });
    }
  }, [Image]);

  const handleClick = () => {
    if (Image.uploading) return;
    inputRef.current.click();
  };

  const changeImg = (event) => {
    let file = event.target.files[0];
    UploadImg(file, Image, setImage);
  };

  return (
    <div
      className="w-full md:w-full h-[12vw] md:h-[4vw] bg-[#F4F5F5] mr-[1vw] mb-[1vw] rounded-[1vw] flex items-center justify-center cursor-pointer border-dashed border-[2px] border-gray-300"
      onClick={handleClick}
    >
      {!Image.uploading ? (
        <Plus color="#4F4F4F" className="w-[30%] h-[30%]" />
      ) : (
        <LoaderCircle
          color="#4F4F4F"
          className="w-[30%] h-[30%] animate-spin"
        />
      )}

      <input
        ref={inputRef}
        type="file"
        className="hidden"
        style={{
          display: "none",
        }}
        onChange={changeImg}
      />
    </div>
  );
};

const UploadImg = async (file, Image, SetImage) => {
  // Initial File before upload
  const formData = new FormData();
  formData.append("image", file);
  SetImage({ ...Image, uploading: true, done: false });

  //   the axios upload
  return await customAxios
    .post("/upload/images/single", formData)
    .then((response) => {
      SetImage({
        ...Image,
        url: response.data.file,
        done: true,
        uploading: false,
      });
    })
    .catch((error) => {
      toast.error("Error Uploading Image");
    });
};

export default FilePDFUpload;
