import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PageContainer from "../../Common/PageContainer/PageContainer";
import LeftColoredTitle from "../../components/Titles/LeftColoredTitle";
import { InscrireBtn } from "../../components/buttons/Buttons";
import { jobApplicationValidation } from "./recrute_validation";
import {
  getDeplomesNames,
  inscriptionRecruteDemand,
} from "../../services/inscription";
import FilePDFUpload from "../../components/FileUpload/FilePDFUpload";
import toast from "react-hot-toast";

const Obl = () => <span className="red">*</span>;

function Recrute() {
  const [deplomes, setDeplomes] = useState({
    ARCHITECTURE: [],
    BUSINESS: [],
    DESIGN: [],
    DROIT: [],
    INFORMATIQUE: [],
  });

  const [form, setForm] = useState({
    // common
    Nom: "",
    Prenom: "",
    Tel: "",
    Email: "",
    CV_LINK: "", // upload input
    MESSAGE: "", // textarea
    jobType: "", // ENSEIGNANT - AUTRE

    // enseignant
    POSTE_SOUHAITEE: "", // ENSEIGNANT PERMANENT - VACATAIRE
    YOU_ARE: "", // UNIVERSITAIRE - PROFESSIONNEL
    DERNIER_DIPLOME_OBTENU: "", // DOCTORAT - MASTER - INGENIEUR - LICENCE - AUTRE
    SPECIALITE: "", // INFORMATIQUE - MARKETING - MANAGEMENT - COMPTABILITE - FINANCE - DROIT PUBLIC - DROIT PRIVE - SCIENCES POLITIQUES - ARCHITECTURE - ARCHITECTURE D’INTERIEUR - DESIGN GRAPHIQUE - ECONOMIE - MATHEMATIQUES - ANGLAIS - FRANCAIS - AUTRE
    DOMAINES_EXPERTISE: "", // normal input
    COURS_ENSEIGNES: "", // deplomes selection
    LANGUE_ENSEIGNEMENT: "", // FRANCAIS - ANGLAIS - ARABE - FRANCAIS & ANGLAIS - FRANCAIS & ARABE - ANGLAIS & ARABE - FRANCAIS & ANGLAIS & ARABE

    // autre
    SERVICE_CONCERNE: "", // ACCUEIL - DEPARTEMENT MARKETING - DEPARTEMENT INFOMORMATIQUE - DIRECTION PEDAGOGIQUE - SERVICE COMPTABLE - SERVICE GRH - SERVICE SCOLARITE - AUTRE
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setForm({
      Nom: "",
      Prenom: "",
      Tel: "",
      Email: "",
      CV_LINK: "",
      MESSAGE: "",
      jobType: "",

      POSTE_SOUHAITEE: "",
      YOU_ARE: "",
      DERNIER_DIPLOME_OBTENU: "",
      SPECIALITE: "",
      DOMAINES_EXPERTISE: "",
      COURS_ENSEIGNES: "",
      LANGUE_ENSEIGNEMENT: "",

      SERVICE_CONCERNE: "",
    });
  };

  useEffect(() => {
    getDeplomesNames().then((resp) => {
      setDeplomes(resp.data);
    });
  }, []);

  const handleSubmit = () => {
    if (jobApplicationValidation(form) === false) return;
    setLoading(true);

    console.log(form);

    inscriptionRecruteDemand(form)
      .then((res) => {
        toast.success("Formulaire envoyé avec succès");
        setLoading(false);
        handleReset();
      })
      .catch((err) => {
        toast.error("Erreur lors de l'envoi du formulaire");
        setLoading(false);
      });
  };

  return (
    <PageContainer title="UIK Recrute" links={[]} with_links={false}>
      <div className={styles.container}>
        <LeftColoredTitle>Vous êtes enseignant ou autre</LeftColoredTitle>

        <div className={styles.form}>
          <div className={styles.w50}>
            <label>
              Nom
              <Obl />
            </label>
            <input name="Nom" value={form.Nom || ""} onChange={handleChange} />
          </div>

          <div className={styles.w50}>
            <label>
              Prénom
              <Obl />
            </label>
            <input
              name="Prenom"
              value={form.Prenom || ""}
              onChange={handleChange}
            />
          </div>

          <div className={styles.w50}>
            <label>
              Num de Tel (avec préfixe)
              <Obl />
            </label>
            <input
              name="Tel"
              value={form.Tel || ""}
              onChange={handleChange}
              placeholder="e.g., +216 12345678"
            />
          </div>

          <div className={styles.w50}>
            <label>
              Email
              <Obl />
            </label>
            <input
              name="Email"
              value={form.Email || ""}
              onChange={handleChange}
              type="email"
            />
          </div>

          <div className={styles.w100}>
            <label>
              Type de travail
              <Obl />
            </label>
            <select
              name="jobType"
              value={form.jobType || ""}
              onChange={handleChange}
            >
              <option value="">- Sélectionner -</option>
              <option value="ENSEIGNANT">ENSEIGNANT</option>
              <option value="AUTRE">AUTRE</option>
            </select>
          </div>

          {form.jobType === "ENSEIGNANT" && (
            <>
              <div className={styles.w50}>
                <label>
                  Poste souhaitée
                  <Obl />
                </label>
                <select
                  name="POSTE_SOUHAITEE"
                  value={form.POSTE_SOUHAITEE || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="ENSEIGNANT PERMANENT">
                    ENSEIGNANT PERMANENT
                  </option>
                  <option value="VACATAIRE">VACATAIRE</option>
                </select>
              </div>

              <div className={styles.w50}>
                <label>
                  Vous êtes
                  <Obl />
                </label>
                <select
                  name="YOU_ARE"
                  value={form.YOU_ARE || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="UNIVERSITAIRE">UNIVERSITAIRE</option>
                  <option value="PROFESSIONNEL">PROFESSIONNEL</option>
                </select>
              </div>

              <div className={styles.w50}>
                <label>
                  Dernier diplôme obtenu
                  <Obl />
                </label>
                <select
                  name="DERNIER_DIPLOME_OBTENU"
                  value={form.DERNIER_DIPLOME_OBTENU || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="DOCTORAT">DOCTORAT</option>
                  <option value="MASTER">MASTER</option>
                  <option value="INGENIEUR">INGENIEUR</option>
                  <option value="LICENCE">LICENCE</option>
                  <option value="AUTRE">AUTRE</option>
                </select>
              </div>

              <div className={styles.w50}>
                <label>
                  Spécialité
                  <Obl />
                </label>
                <select
                  name="SPECIALITE"
                  value={form.SPECIALITE || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="INFORMATIQUE">INFORMATIQUE</option>
                  <option value="MARKETING">MARKETING</option>
                  <option value="MANAGEMENT">MANAGEMENT</option>
                  <option value="COMPTABILITE">COMPTABILITE</option>
                  <option value="FINANCE">FINANCE</option>
                  <option value="DROIT PUBLIC">DROIT PUBLIC</option>
                  <option value="DROIT PRIVE">DROIT PRIVE</option>
                  <option value="SCIENCES POLITIQUES">
                    SCIENCES POLITIQUES
                  </option>
                  <option value="ARCHITECTURE">ARCHITECTURE</option>
                  <option value="ARCHITECTURE D’INTERIEUR">
                    ARCHITECTURE D’INTERIEUR
                  </option>
                  <option value="DESIGN GRAPHIQUE">DESIGN GRAPHIQUE</option>
                  <option value="ECONOMIE">ECONOMIE</option>
                  <option value="MATHEMATIQUES">MATHEMATIQUES</option>
                  <option value="ANGLAIS">ANGLAIS</option>
                  <option value="FRANCAIS">FRANCAIS</option>
                  <option value="AUTRE">AUTRE</option>
                </select>
              </div>

              <div className={styles.w100}>
                <label>
                  Domaines d'expertise
                  <Obl />
                </label>
                <input
                  name="DOMAINES_EXPERTISE"
                  value={form.DOMAINES_EXPERTISE || ""}
                  onChange={handleChange}
                />
              </div>

              <div className={styles.w100}>
                <label>
                  Quel vous vouler enseigner ?<Obl />
                </label>
                <select
                  name="COURS_ENSEIGNES"
                  value={form.COURS_ENSEIGNES || ""}
                  onChange={handleChange}
                >
                  <option style={{ fontWeight: "bold" }} selected={true}>
                    - Sélectionner -
                  </option>

                  <option
                    value="ÉCOLE D’ARCHITECTURE ET DESIGN"
                    style={{ fontWeight: "bold" }}
                    disabled={true}
                  >
                    ÉCOLE D’ARCHITECTURE ET DESIGN
                  </option>

                  {deplomes.ARCHITECTURE.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}

                  <option
                    value="ÉCOLE DE DROIT"
                    style={{ fontWeight: "bold" }}
                    disabled={true}
                  >
                    ÉCOLE DE DROIT
                  </option>

                  {deplomes.DROIT.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}

                  <option
                    value="ÉCOLE DE BUSINESS"
                    style={{ fontWeight: "bold" }}
                    disabled={true}
                  >
                    ÉCOLE DE BUSINESS
                  </option>

                  {deplomes.BUSINESS.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}

                  <option
                    value="ÉCOLE D'INFORMATIQUE ET TECHNOLOGIES"
                    style={{ fontWeight: "bold" }}
                    disabled={true}
                  >
                    ÉCOLE D'INFORMATIQUE ET TECHNOLOGIES
                  </option>

                  {deplomes.INFORMATIQUE.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}

                  <option
                    value="ÉCOLE DE DESIGN"
                    style={{ fontWeight: "bold" }}
                    disabled={true}
                  >
                    ÉCOLE DE DESIGN
                  </option>

                  {deplomes.DESIGN.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className={styles.w100}>
                <label>
                  Langue d'enseignement
                  <Obl />
                </label>
                <select
                  name="LANGUE_ENSEIGNEMENT"
                  value={form.LANGUE_ENSEIGNEMENT || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="FRANCAIS">FRANCAIS</option>
                  <option value="ANGLAIS">ANGLAIS</option>
                  <option value="ARABE">ARABE</option>
                  <option value="FRANCAIS & ANGLAIS">FRANCAIS & ANGLAIS</option>
                  <option value="FRANCAIS & ARABE">FRANCAIS & ARABE</option>
                  <option value="ANGLAIS & ARABE">ANGLAIS & ARABE</option>
                  <option value="FRANCAIS & ANGLAIS & ARABE">
                    FRANCAIS & ANGLAIS & ARABE
                  </option>
                </select>
              </div>
            </>
          )}

          {form.jobType === "AUTRE" && (
            <>
              <div className={styles.w100}>
                <label>
                  Service concerné
                  <Obl />
                </label>
                <select
                  name="SERVICE_CONCERNE"
                  value={form.SERVICE_CONCERNE || ""}
                  onChange={handleChange}
                >
                  <option value="">- Sélectionner -</option>
                  <option value="ACCUEIL">ACCUEIL</option>
                  <option value="DEPARTEMENT MARKETING">
                    DEPARTEMENT MARKETING
                  </option>
                  <option value="DEPARTEMENT INFOMORMATIQUE">
                    DEPARTEMENT INFOMORMATIQUE
                  </option>
                  <option value="DIRECTION PEDAGOGIQUE">
                    DIRECTION PEDAGOGIQUE
                  </option>
                  <option value="SERVICE COMPTABLE">SERVICE COMPTABLE</option>
                  <option value="SERVICE GRH">SERVICE GRH</option>
                  <option value="SERVICE SCOLARITE">SERVICE SCOLARITE</option>
                  <option value="AUTRE">AUTRE</option>
                </select>
              </div>
            </>
          )}

          <FilePDFUpload
            label="Ajouter votre CV"
            className={styles.w100}
            name="CV_LINK"
            value={form.CV_LINK}
            required={true}
            onChange={handleChange}
          />

          <div className={styles.w100}>
            <label>Message</label>

            <textarea
              value={form.MESSAGE}
              onChange={handleChange}
              name="MESSAGE"
              rows={5}
            />
          </div>

          <div className={styles.actions}>
            <InscrireBtn
              onClick={handleSubmit}
              disabled={loading}
              className={styles.submitBtn}
            >
              {loading ? "Envoi en cours..." : "Envoyer"}
            </InscrireBtn>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default Recrute;
